import { mockSchedulingLinkDto, mockSchedulingLinkDtos } from "../../cicd/jest/fixtures/schedulingLinks";
import { Override } from "../types";
import { DayOfWeek } from "./Calendars";
import { ConferenceType } from "./OneOnOnes";
import { TransformDomain } from "./types";
import { ThinPerson } from "./Users";

export type SchedulingLinkIconType = "LIGHT" | "COFFEE" | "BOLT" | "COMMENT" | "STAR" | "AIRPLANE" | "TWINKLE" | "COMPASS" | "SUN" | "SMILE";

export type SchedulingLinkDto = {
  id: string;
  title: string;
  description: string;
  enabled: boolean;
  organizer: ThinPerson;
  timePolicy: "WORK" | "PERSONAL" | "MEETING" | null;
  daysActive: DayOfWeek[] | null;
  windowStart: string | null;
  windowEnd: string | null;
  minChunkSize: number;
  maxChunkSize: number;
  daysIntoFuture: number | null;
  startDate: string | null;
  endDate: string | null;
  priority: "DEFAULT" | "HIGH";
  conferenceType: ConferenceType | null;
  iconType: SchedulingLinkIconType;
};

export type SchedulingLink = Override<SchedulingLinkDto, {
  readonly id: string;
}>;

export const dtoToSchedulingLink = (dto: SchedulingLinkDto): SchedulingLink => dto as SchedulingLink;
export const schedulingLinkToDto = (link: SchedulingLink): SchedulingLinkDto => link as SchedulingLinkDto;

export class SchedulingLinksDomain extends TransformDomain<SchedulingLink, SchedulingLinkDto> {
  resource = "SchedulingLink";
  cacheKey = "scheduling_links";
  pk = "id";

  public serialize = schedulingLinkToDto;
  public deserialize = dtoToSchedulingLink;

  list = this.deserializeResponse(async () => {
    return new Promise<SchedulingLink[]>((resolve) => {
      setTimeout(() => resolve(mockSchedulingLinkDtos()), 2000);
    });
  });

  get = this.deserializeResponse((id: string): Promise<SchedulingLink> => {
    const mock = mockSchedulingLinkDto();
    mock.id = id;
    return new Promise<SchedulingLink>((resolve) => {
      setTimeout(() => resolve(mockSchedulingLinkDto()), 2000);
    });
  })
}
