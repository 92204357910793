import { SchedulingLinkDto } from "../../../src/reclaim-api/SchedulingLinks";

export const mockSchedulingLinkDto = (): SchedulingLinkDto => ({
  id: "8d8447d7-c93d-48d1-951d-fe6365944244",
  title: "30 minute meeting",
  description:
    "A long description of my meeting link. Sit dicta perspiciatis solutall am ut saepe et id facere omnis. Qui aspernatur necess itatibus quidem et in vel.",
  enabled: true,
  organizer: {
    avatarUrl: "https://lh3.googleusercontent.com/a-/AFdZucojejfHkMEA5O6Sp_81QYNFKT1C1OV93XJzyhsH=s96-c",
    email: "reclaim.office2@gmail.com",
    name: "Michael Scott",
    userId: "ee8be6dc-7fc0-435c-9bb1-8d12c7c0b581",
  },
  timePolicy: "MEETING",
  daysActive: null,
  windowStart: null,
  windowEnd: null,
  minChunkSize: 2,
  maxChunkSize: 2,
  daysIntoFuture: 14,
  startDate: null,
  endDate: null,
  priority: "DEFAULT",
  conferenceType: null,
  iconType: "BOLT",
});

export const mockSchedulingLinkDtos = (): SchedulingLinkDto[] => {
  const archetype = mockSchedulingLinkDto();
  const description = "A short description of my meeting link.";

  return [
    { ...archetype, title: "Short Meeting", description, minChunkSize: 1, maxChunkSize: 2, iconType: "COFFEE" },
    archetype,
    { ...archetype, title: "Priority Meeting", description, minChunkSize: 4, maxChunkSize: 4, iconType: "LIGHT" },
  ];
};
